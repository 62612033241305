<template>
    <div id="app" class="IntelligentSecurity">
        <div class="main">
            <div class="fullScreen">
                <i class="icon-quanping" v-show="!fullScreen" @click="fullScreenClick" style="cursor: pointer;"></i>
                <i class="icon-tuichuquanping" v-show="fullScreen" @click="outFullScreenClick"
                    style="cursor: pointer;"></i>
            </div>
            <div class="header">
                <div class="dateTime">
                    <span>{{ currentTime }}</span>
                    <span>{{ currentDate }}</span>
                </div>
                <!-- 机构总数量 -->
                <div class="statistics">
                    <div>
                        <img src="../../../assets/visualization/IntelligentSecurity/headerIcon1.png" alt="">
                    </div>
                    <div>
                        <span>在建项目</span>
                        <span style="color: #FFB026;">{{ formaterNumber(headerData.institutionsAmount) }} <span>个</span>
                        </span>
                    </div>
                </div>
                <!-- 机构总人数 -->
                <div class="statistics">
                    <div>
                        <img src="../../../assets/visualization/IntelligentSecurity/headerIcon2.png" alt="">
                    </div>
                    <div>
                        <span>在建项目人数</span>
                        <span style="color: #26D8FF;">{{ formaterNumber(headerData.institutionsPersonnelAmount) }}
                            <span>人</span> </span>
                    </div>
                </div>
                <!-- 专职安全环保人员 -->
                <div class="statistics">
                    <div>
                        <img src="../../../assets/visualization/IntelligentSecurity/headerIcon3.png" alt="">
                    </div>
                    <div>
                        <span>专职安全环保人员</span>
                        <span style="color: #27FFCF;">{{ formaterNumber(headerData.protectionPersonnel) }}
                            <span>人</span> </span>
                    </div>
                </div>
                <!-- 境外风险项目数占比统计 -->
                <div class="statistics">
                    <div>
                        <img src="../../../assets/visualization/IntelligentSecurity/headerIcon4.png" alt="">
                    </div>
                    <div>
                        <span>境外风险项目数占比</span>
                        <span style="color: #0BED8B;">
                            {{ formaterNumber(headerData.protectionInvolvement) }}<span>%</span>
                        </span>
                    </div>
                </div>
                <!-- 境外风险项目人数占比统计 -->
                <div class="statistics">
                    <div>
                        <img src="../../../assets/visualization/IntelligentSecurity/headerIcon5.png" alt="">
                    </div>
                    <div>
                        <span>境外风险项目人数占比</span>
                        <span style="color: #1FEBDF;">{{ formaterNumber(headerData.safeHours) }} <span>%</span> </span>
                    </div>
                </div>
                <div class="switchProject">
                    <avue-input-tree default-expand-all v-model="deptId" placeholder="请选择所属部门" @change="chooseDept"
                        :clearable="false" :props="inputTreeProps" :dic="nowUserDeptList"></avue-input-tree>
                </div>
            </div>
            <div class="body">
                <!-- 左 -->
                <div>
                    <div class="echartsModelS">
                        <div class="echartsModelS-title">在建项目、运营机构概况</div>
                        <div class="echartsModelS-chart listBoxNew">
                            <div>
                                <span class="listBox-title">境内项目</span>
                                <div class="itemS">
                                    <img src="../../../assets/visualization/IntelligentSecurity/projectIcon.png" alt="">
                                    <span>项目数</span>
                                    <span style="color: #ece427;">
                                        {{ formaterNumber(InstitutionalOverview.domesticPojAmount) }}
                                    </span>
                                </div>
                                <div class="itemS">
                                    <img src="../../../assets/visualization/IntelligentSecurity/personIcon.png" alt="">
                                    <span>人员数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.domesticPersonnelAmount) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <span class="listBox-title">工厂</span>
                                <div class="itemS">
                                    <img src="../../../assets/visualization/IntelligentSecurity/functionIcon.png"
                                        alt="">
                                    <span>工厂数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.factoryAmount) }}
                                    </span>
                                </div>
                                <div class="itemS">
                                    <img src="../../../assets/visualization/IntelligentSecurity/personIcon.png" alt="">
                                    <span>人员数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.factoryPersonnelAmount) }}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <span class="listBox-title">境外项目-机构</span>
                                <div class="itemL">
                                    <img src="../../../assets/visualization/IntelligentSecurity/projectIcon2.png"
                                        alt="">
                                    <span>项目/机构数</span>
                                    <span style="color: #ece427;">
                                        {{ formaterNumber(InstitutionalOverview.abroadPojAmount) }}
                                    </span>
                                </div>
                                <div class="itemL">
                                    <img src="../../../assets/visualization/IntelligentSecurity/personIcon.png" alt="">
                                    <span>人员数</span>
                                    <span style="color: #00aaff;">
                                        {{ formaterNumber(InstitutionalOverview.abroadpersonnelAmount) }}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="echartsModelS">
                        <div class="echartsModelS-title">班前会</div>
                        <div class="echartsModelS-chart">
                            <!-- 循环内部循环 -->
                            <el-carousel height="100%" interval="5000" indicator-position="none" arrow="never"
                                v-show="BQJDHList.length > '0'">
                                <el-carousel-item v-for="(item, index) in BQJDHList" :key="index">
                                    <div class="elItem" v-for="(items, indexs) in item.data" :key="indexs">
                                        <!-- ../../../assets/visualization/securityManagement/noImg.png -->
                                        <div class="elItemImageBox">
                                            <img class="elItemImage" :src="items.imgSrc" alt="" />
                                        </div>
                                        <div class="LBtxt">
                                            <div>
                                                <span class="LBtitle" style="color: #00f0ff; font-weight: 500">
                                                    {{ items.title }}
                                                </span>
                                                <br />
                                                <span class="dian2 LBcontet" style="color: #fff; font-weight: 500">
                                                    {{ items.meetingContents }}
                                                </span>
                                            </div>
                                            <div class="LBtime" style="color: #fff; font-weight: 500">
                                                {{ items.meetingTime }}
                                            </div>
                                        </div>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                            <div class="Nodataavailableatthemoment"
                                style="display: flex;justify-content: center;width: 100%;align-items: center;height: 100%;"
                                v-show="BQJDHList.length == '0'">
                                <img style="width: 27%;" :src="emptyUrl" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="echartsModelS">
                        <div class="echartsModelS-title">项目整改统计图</div>
                        <div class="echartsModelS-chart" id="echartsLB"></div>
                    </div>
                </div>
                <!-- 中 -->
                <div>
                    <div class="earthMap">
                        <div v-show="activeWordMap" id="earthMap" style="position: relative;z-index: 888;"></div>
                        <div v-show="!activeWordMap" id="wordMap" style="position: relative;z-index: 888;"></div>
                        <div class="wordMapBox">
                            <div :class="{ 'activeWordMap': activeWordMap, 'wordMap': !activeWordMap }"
                                @click="checkMapState(true)">3D地图</div>
                            <div :class="{ 'activeWordMap': !activeWordMap, 'wordMap': activeWordMap }"
                                @click="checkMapState(false)">世界地图</div>
                        </div>
                        <loading :text="'正在加载地图'" :loadingState="loadingState"></loading>
                        <!-- 炫光 -->
                        <div class="b1">
                            <div class="xg0 xg"><!--底层内环--></div>
                            <div class="xg1 xg"><!--底层内环--></div>
                            <div class="xg2 xg"><!--底层内环--></div>
                            <div class="xg3 xg"><!--底层内环--></div>
                            <div class="xg4 xg"><!--底层内环--></div>
                            <div class="xg5 xg"><!--底层内环--></div>
                            <div class="xg6 xg"><!--底层内环--></div>
                            <div class="xg61 xg"><!--底层内环--></div>
                            <div class="xg7 xg"><!--底层内环--></div>
                            <div class="xg2c xg"><!--底层内环--></div>
                            <div class="xg2c1 xg"><!--底层内环--></div>
                        </div>
                        <div class="rainBox">
                            <canvas class="rain" width="1186" height="731"></canvas>
                            <canvas class="rain2" width="1186" height="731"></canvas>
                            <canvas class="rain3" width="1186" height="731"></canvas>
                        </div>
                    </div>
                </div>
                <!-- 右 -->
                <div>
                    <div class="echartsModelS">
                        <div class="echartsModelS-title">安全培训覆盖率</div>
                        <div class="echartsModelS-chart circleChart">
                            <div id="echartsRT"></div>
                            <div>
                                <div>培训次数</div>
                                <div>参与培训人次</div>
                                <div>{{ formaterNumber(safetyRate.trainingAmount) }}</div>
                                <div>{{ formaterNumber(safetyRate.trainingPersonnel) }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="echartsModelS">
                        <div class="echartsModelS-title">培训次数统计</div>
                        <div class="echartsModelS-chart" id="echartsRC"></div>
                    </div>
                    <div class="echartsModelS">
                        <div class="echartsModelS-title">年度安全检查统计</div>
                        <div class="echartsModelS-chart" id="echartsRB"></div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 炫光 -->
        <!-- <div class="rainBox">
            <canvas class="rain" width="1186" height="731"></canvas>
            <canvas class="rain2" width="1186" height="731"></canvas>
            <canvas class="rain3" width="1186" height="731"></canvas>
        </div> -->
    </div>
</template>
<script>
import loading from '../components/loading.vue';
import * as echarts from 'echarts';
// import worldJson from '../../../util/world.js';
import world from './world.json'
import { visualization, worldData, dangerCountry, dbtbeforeclass } from '../../../api/visualization/index'
import { mapGetters } from "vuex";
import {
    nowUserDept
} from "@/api/user";
import { fullscreenToggel, listenfullscreen } from "@/util/util";
export default {
    name: "IntelligentSecurity",
    components: {
        loading
    },
    data() {
        return {
            fullScreen: false,
            loadingState: false,
            currentTime: '',
            currentDate: '',
            activeWordMap: true,
            wordShowType: true,
            nowYear: new Date().getFullYear(),
            nowMonth: new Date().getMonth() + 1,
            fontSize: window.innerWidth / 100,
            headerData: {
                institutionsAmount: 0, // 在建项目/机构总数量
                institutionsPersonnelAmount: 0, // 在建项目/机构总人数
                protectionPersonnel: 0, // 专职安全环保人员
                protectionInvolvement: 0, // 安全环保总投入
                safeHours: 0 // 安全生产工时
            },
            InstitutionalOverview: {
                domesticPojAmount: 0, // 境内项目数
                domesticPersonnelAmount: 0, // 境内人员数
                factoryAmount: 0, // 工厂数
                factoryPersonnelAmount: 0, // 工厂人员数
                abroadPojAmount: 0, // 项目/机构数
                abroadpersonnelAmount: 0 // 境外人员数
            },
            // 总体隐患整改率
            dangerRate: {
                rate: 0, // 概率
                dangerAmount: 0, // 隐患总数
                rectified: 0 // 已整改隐患
            },
            // 安全培训覆盖率
            safetyRate: {
                rate: 0, // 概率
                trainingAmount: 0, // 培训次数
                trainingPersonnel: 0 //参与培训人次
            },
            earthData: [
                // {
                //     name: '项目1', value: [121.02929, 31.39050],
                //     info: {
                //         chinesePersonnelNum: '1.313人',
                //         foreignNationalsNum: '1.313人'
                //     },
                // },
            ],
            linesList: [],
            inputTreeProps: {
                label: "title",
                value: "id",
            },
            nowUserDeptList: [],
            deptId: '',
            emptyUrl: require("../../../assets/visualization/noData.png"),
            BQJDHList: []
        }
    },
    created() {
        // 正式中材国际编码 1782377952097869826
        // 开发中材国际编码 1722789594114084866
        // 测试中材国际编码 1782377952097869826
        if (window.location.hostname == '123.60.160.227') {
            this.deptId = '1782377952097869826'
        } else if (window.location.hostname == '39.106.228.40') {
            this.deptId = '1782377952097869826'
        } else {
            this.deptId = '1722789594114084866'
        }
    },
    mounted() {
        echarts.registerMap('world', world);


        // 获取系统时间
        this.getTime();
        this.rain()
        this.rain2()
        this.rain3();

        nowUserDept().then((res) => {
            if (res.data.code === 200) {
                this.nowUserDeptList = res.data.data;
            }
        });
    },
    computed: {
        ...mapGetters(["userInfo"])
    },
    watch: {
        activeWordMap(val) {
            if (!val && this.wordShowType) {
                this.wordShowType = false
                this.loadingState = true
                this.$nextTick(() => {
                    this.wordEcharts()
                })
            }
        },
        fullScreen(val) {
            // this.getEchartsLC()
            // this.getEchartsLB()
            this.getEchartsRT()
            this.getEchartsRC()
            this.getEchartsRB()
        },
    },
    methods: {
        fullScreenClick() {
            this.fullScreen = true
            fullscreenToggel()
        },
        outFullScreenClick() {
            this.fullScreen = false
            fullscreenToggel()
        },
        checkMapState(states) {
            this.activeWordMap = states
            setTimeout(() => {
                // 获取 ECharts 实例对象
                var earthMapChart = echarts.getInstanceByDom(document.getElementById('earthMap'));
                var wordMapChart = echarts.getInstanceByDom(document.getElementById('wordMap'));
                // 如果实例对象存在，则调用 resize 方法
                if (earthMapChart) {
                    earthMapChart.resize();
                }

                if (wordMapChart) {
                    wordMapChart.resize();
                }
            }, 0);
        },
        getHeaders() {
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0001_Y&indexCodes=ZB0002_Y&indexCodes=ZB0011_Y&indexCodes=ZB0015_Y&indexCodes=ZB0003_Y`
            visualization(params).then(res => {
                let data = res.data.data
                // console.log('头部数据', data);
                this.headerData.institutionsAmount = data.ZB0001_Y.length == 0 ? 0 : data.ZB0001_Y[0].value
                this.headerData.institutionsPersonnelAmount = data.ZB0002_Y.length == 0 ? 0 : data.ZB0002_Y[0].value
                this.headerData.protectionPersonnel = data.ZB0003_Y.length == 0 ? 0 : data.ZB0003_Y[0].value
                this.headerData.protectionInvolvement = data.ZB0011_Y.length == 0 ? 0 : data.ZB0011_Y[0].value
                this.headerData.safeHours = data.ZB0015_Y.length == 0 ? 0 : data.ZB0015_Y[0].value
            })
        },
        getInstitutionalOverview() {
            // ZB0016_Y 境内人员数  ZB00111_Y 工厂人员总数  ZB0014_Y 境外人员数
            // ZB0020_Y 境内项目数  ZB0098_Y  工厂总数      ZB0010_Y 境外项目机构数量
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0016_Y&indexCodes=ZB0020_Y&indexCodes=ZB00111_Y&indexCodes=ZB0098_Y&indexCodes=ZB0014_Y&indexCodes=ZB0010_Y`
            visualization(params).then(res => {
                // console.log('测试新版接口', res.data.data);
                let data = res.data.data
                this.InstitutionalOverview.domesticPersonnelAmount = data.ZB0020_Y.length == 0 ? 0 : data.ZB0020_Y[0].value // 境内人员数
                this.InstitutionalOverview.abroadPojAmount = data.ZB0010_Y.length == 0 ? 0 : data.ZB0010_Y[0].value // 境外项目机构数量
                this.InstitutionalOverview.abroadpersonnelAmount = data.ZB0014_Y.length == 0 ? 0 : data.ZB0014_Y[0].value // 境外人员数
                this.InstitutionalOverview.domesticPojAmount = data.ZB0016_Y.length == 0 ? 0 : data.ZB0016_Y[0].value // 境内项目数
                this.InstitutionalOverview.factoryAmount = data.ZB0098_Y.length == 0 ? 0 : data.ZB0098_Y[0].value // 工厂总数
                this.InstitutionalOverview.factoryPersonnelAmount = data.ZB00111_Y.length == 0 ? 0 : data.ZB00111_Y[0].value // 工厂人员总数
            })
        },
        chooseDept(val) {
            console.log('切换项目', val);
            this.deptId = val
            this.getEcharts()
            // 在建项目/机构总数量
            this.getHeaders()
            this.getInstitutionalOverview();
            this.wordEcharts()
        },
        getEcharts() {
            this.getWorldData()
            this.bqhFunction()
            this.getEchartsLB()
            this.getEchartsRT()
            this.getEchartsRC()
            this.getEchartsRB()
            // this.wordEcharts()
        },
        getTime() {
            setInterval(() => {
                this.getDate();
            }, 1000);
        },
        getDate() {
            const now = new Date();
            this.currentDate = `${now.getFullYear()} ${now.getMonth() + 1} ${now.getDate()}`
            this.currentTime = `${this.padZero(now.getHours())}:${this.padZero(now.getMinutes())}:${this.padZero(now.getSeconds())}`
        },
        padZero(num) {
            return num.toString().padStart(2, '0');
        },
        // 获取世界地图相关数据
        getWorldData() {
            this.loadingState = true
            worldData({ orgId: this.deptId }).then(res => {
                // console.log('世界地图人员信息', res);
                if (res.data.code == 200) {
                    res.data.data.forEach(item => {
                        this.earthData.push({
                            name: item.prj_name,
                            value: [item.longitude, item.latitude],
                            info: {
                                chinesePersonnelNum: item.chinesePersonnelNum,
                                foreignNationalsNum: item.foreignNationalsNum
                            }
                        })

                    })
                    this.earthData.forEach(item => {
                        this.linesList.push([[116.475, 40.0109], item.value])
                    })
                    this.earthEcharts()
                    this.loadingState = false
                }
            })
        },
        // 添加千位符
        formaterNumber(num) {
            return new Intl.NumberFormat().format(num)
        },
        // 中间地球
        earthEcharts() {
            echarts.init(document.getElementById('earthMap')).dispose();
            let that = this
            var myChart = echarts.init(document.getElementById('earthMap'));
            myChart.setOption({
                globe: {
                    baseTexture: require('../../../assets/visualization/IntelligentSecurity/earth.jpg'),
                    heightTexture: require('../../../assets/visualization/IntelligentSecurity/earthHB.jpg'),
                    displacementScale: 0.01,
                    shading: 'lambert',
                    realisticMaterial: {
                        metalness: 0.2, // 设置金属感
                        roughness: 0.2, // 设置粗糙度
                        reflectivity: 1, // 设置反射率
                    },
                    atmosphere: {
                        show: true,
                        color: "#377abd",
                        thickness: 0.3,
                        quality: 0.1,
                    },
                    postEffect: { // 后期特效
                        enable: false,
                        SSAO: {
                            enable: true,
                            radius: 2,
                            intensity: 1.5
                        },
                        depthOfField: {
                            enable: false,
                            focalDistance: 1000,
                            fstop: 1.8,
                            blurRadius: 1
                        },
                        bloom: {
                            enable: false,
                            threshold: 1,
                            blurSize: 1,
                            blurAmount: 0,
                            bloomStrength: 3,
                            bloomRadius: 0
                        },
                    },
                    light: {
                        main: {
                            intensity: 0.3,
                            shadow: true,
                            quality: 'high',
                        },
                        ambient: {
                            intensity: 0.8
                        }
                    },
                    layers: [
                        { // 云彩  当前赤道环绕层
                            show: true,
                            type: 'overlay', // 图层类型，取值为 'base'、'overlay' 或 'none'，默认为 'base'。
                            texture: require('../../../assets/visualization/IntelligentSecurity/xg/gh1.png'),
                            shading: 'phong', // 光照模型类型，取值为 'lambert'、'phong' 或 'blinn'，默认为 'lambert'。
                            distance: 5, // 图层距离
                            // intensity: 0, // 图层颜色强度参数 0-∞
                            // blendMode: 'screen'
                        },
                        { // 云彩  当前赤道环绕层
                            show: true,
                            type: 'overlay', // 图层类型，取值为 'base'、'overlay' 或 'none'，默认为 'base'。
                            texture: require('../../../assets/visualization/IntelligentSecurity/xg/gh2.png'),
                            shading: 'phong', // 光照模型类型，取值为 'lambert'、'phong' 或 'blinn'，默认为 'lambert'。
                            distance: 10, // 图层距离
                            // intensity: 0, // 图层颜色强度参数 0-∞
                            // blendMode: 'screen'
                        },
                        { // 云彩  当前赤道环绕层
                            show: true,
                            type: 'overlay', // 图层类型，取值为 'base'、'overlay' 或 'none'，默认为 'base'。
                            texture: require('../../../assets/visualization/IntelligentSecurity/xg/gh3.png'),
                            shading: 'phong', // 光照模型类型，取值为 'lambert'、'phong' 或 'blinn'，默认为 'lambert'。
                            distance: 15, // 图层距离
                            // intensity: 0, // 图层颜色强度参数 0-∞
                            // blendMode: 'screen'
                        },
                    ],
                    viewControl: {
                        autoRotate: true,
                        alpha: 20,
                        beta: -5,
                        distance: 250,
                        autoRotateStart: [113.27, 23.13],
                    },
                },
            });
            window.addEventListener("resize", function () {
                setTimeout(() => {
                    myChart.resize();
                }, 0);
            });

            document.getElementById('earthMap').addEventListener('mouseenter', function () {
                myChart.setOption({
                    globe: {
                        viewControl: {
                            autoRotate: false // 鼠标悬浮时停止旋转
                        }
                    }
                });
            });

            document.getElementById('earthMap').addEventListener('mouseleave', function () {
                myChart.setOption({
                    globe: {
                        viewControl: {
                            autoRotate: true // 鼠标离开时恢复旋转
                        }
                    }
                });
            });
            // 地点信息
            myChart.setOption({
                series: [
                    {
                        type: 'scatter3D',
                        coordinateSystem: 'globe', // 'globe'：地球坐标系
                        showEffectOn: 'render', // 'render'：在渲染完成后显示特效。'emphasis'：在鼠标悬停时显示特效。'none'：不显示特效。
                        itemStyle: {
                            color: "red",
                            opacity: 1
                            // borderColor: "red",
                            // borderWidth: 2,
                            // shadowColor: "red",
                            // shadowBlur: 10
                        },
                        // symbol: 'circle',
                        hoverAnimation: false,
                        symbolSize: window.innerWidth / 100 * 0.3,
                        data: that.earthData.map(function (d) {

                            return {
                                name: d.name,
                                value: [d.value[0], d.value[1], 0],
                                label: {
                                    show: false, // 默认不显示标签
                                    emphasis: { // 高亮状态的样式定义
                                        formatter: function (params) {
                                            // 根据需要展示的内容定制
                                            let content = '项目名称\n\n' + params.data.name; // 可以根据data里的属性定制内容
                                            if (params.data.info) { // 假设数据中还有其他属性info
                                                content += '\n\n中方人数:    ' + params.data.info.chinesePersonnelNum + '\n\n外籍人数:    ' + params.data.info.foreignNationalsNum;
                                            }
                                            return content;
                                        },
                                        position: 'top',
                                        show: true, // 鼠标悬浮时显示
                                        color: "#fff",
                                        backgroundColor: 'rgba(8, 66, 165, 0.8)',
                                        padding: 10, // 上右下左的内边距分别为 10px
                                        borderWidth: 1, // 边框宽度为 1 像素
                                        borderColor: '#34a0e9', // 红色边框
                                        borderRadius: 5,
                                    }
                                },
                                // 假设除了name之外，我们还想显示其他信息，比如info
                                info: d.info, // 自定义的额外信息
                            };
                        }),
                        blendMode: 'lighter',
                    },
                    {
                        type: 'lines3D',
                        coordinateSystem: 'globe',
                        data: this.linesList,
                        // lineStyle: {
                        //     normal: {
                        //         // type: 'dotted', // 把实现变成虚线
                        //         // color: '#586fb5', //连接线颜色
                        //         width: 1.5,//线条宽度
                        //         opacity: 0.8,
                        //         curveness: 0.3,//线条弧度 0-1
                        //     }
                        // },
                        blendMode: "lighter",
                        effect: {
                            show: true,
                        },
                        silent: false,
                    }
                ],
            });

        },
        // 世界地图
        wordEcharts() {
            echarts.init(document.getElementById('wordMap')).dispose();
            // echarts.init(document.getElementById('wordMap')).dispose();
            var myChart = echarts.init(document.getElementById('wordMap'));
            let that = this
            var nameMap = {
                "Canada": "加拿大",
                "Turkmenistan": "土库曼斯坦",
                "Saint Helena": "圣赫勒拿",
                "Lao PDR": "老挝",
                "Lithuania": "立陶宛",
                "Cambodia": "柬埔寨",
                "Ethiopia": "埃塞俄比亚",
                "Faeroe Is.": "法罗群岛",
                "Swaziland": "斯威士兰",
                "Palestine": "巴勒斯坦",
                "Belize": "伯利兹",
                "Argentina": "阿根廷",
                "Bolivia": "玻利维亚",
                "Cameroon": "喀麦隆",
                "Burkina Faso": "布基纳法索",
                "Aland": "奥兰群岛",
                "Bahrain": "巴林",
                "Saudi Arabia": "沙特阿拉伯",
                "Fr. Polynesia": "法属波利尼西亚",
                "Cape Verde": "佛得角",
                "W. Sahara": "西撒哈拉",
                "Slovenia": "斯洛文尼亚",
                "Guatemala": "危地马拉",
                "Guinea": "几内亚",
                "Dem. Rep. Congo": "刚果（金）",
                "Germany": "德国",
                "Spain": "西班牙",
                "Liberia": "利比里亚",
                "Netherlands": "荷兰",
                "Jamaica": "牙买加",
                "Solomon Is.": "所罗门群岛",
                "Oman": "阿曼",
                "Tanzania": "坦桑尼亚",
                "Costa Rica": "哥斯达黎加",
                "Isle of Man": "曼岛",
                "Gabon": "加蓬",
                "Niue": "纽埃",
                "Bahamas": "巴哈马",
                "New Zealand": "新西兰",
                "Yemen": "也门",
                "Jersey": "泽西岛",
                "Pakistan": "巴基斯坦",
                "Albania": "阿尔巴尼亚",
                "Samoa": "萨摩亚",
                "Czech Rep.": "捷克",
                "United Arab Emirates": "阿拉伯联合酋长国",
                "Guam": "关岛",
                "India": "印度",
                "Azerbaijan": "阿塞拜疆",
                "N. Mariana Is.": "北马里亚纳群岛",
                "Lesotho": "莱索托",
                "Kenya": "肯尼亚",
                "Belarus": "白俄罗斯",
                "Tajikistan": "塔吉克斯坦",
                "Turkey": "土耳其",
                "Afghanistan": "阿富汗",
                "Bangladesh": "孟加拉国",
                "Mauritania": "毛里塔尼亚",
                "Dem. Rep. Korea": "朝鲜",
                "Saint Lucia": "圣卢西亚",
                "Br. Indian Ocean Ter.": "英属印度洋领地",
                "Mongolia": "蒙古国",
                "France": "法国",
                "Cura?ao": "库拉索岛",
                "S. Sudan": "南苏丹",
                "Rwanda": "卢旺达",
                "Slovakia": "斯洛伐克",
                "Somalia": "索马里",
                "Peru": "秘鲁",
                "Vanuatu": "瓦努阿图",
                "Norway": "挪威",
                "Malawi": "马拉维",
                "Benin": "贝宁",
                "St. Vin. and Gren.": "圣文森特和格林纳丁斯",
                "Korea": "韩国",
                "Singapore": "新加坡",
                "Montenegro": "黑山",
                "Cayman Is.": "开曼群岛",
                "Togo": "多哥",
                "China": "中国",
                "Heard I. and McDonald Is.": "赫德岛和麦克唐纳群岛",
                "Armenia": "亚美尼亚",
                "Falkland Is.": "马尔维纳斯群岛（福克兰）",
                "Ukraine": "乌克兰",
                "Ghana": "加纳",
                "Tonga": "汤加",
                "Finland": "芬兰",
                "Libya": "利比亚",
                "Dominican Rep.": "多米尼加",
                "Indonesia": "印度尼西亚",
                "Mauritius": "毛里求斯",
                "Eq. Guinea": "赤道几内亚",
                "Sweden": "瑞典",
                "Vietnam": "越南",
                "Mali": "马里",
                "Russia": "俄罗斯",
                "Bulgaria": "保加利亚",
                "United States": "美国",
                "Romania": "罗马尼亚",
                "Angola": "安哥拉",
                "Chad": "乍得",
                "South Africa": "南非",
                "Fiji": "斐济",
                "Liechtenstein": "列支敦士登",
                "Malaysia": "马来西亚",
                "Austria": "奥地利",
                "Mozambique": "莫桑比克",
                "Uganda": "乌干达",
                "Japan": "日本",
                "Niger": "尼日尔",
                "Brazil": "巴西",
                "Kuwait": "科威特",
                "Panama": "巴拿马",
                "Guyana": "圭亚那合作共和国",
                "Madagascar": "马达加斯加",
                "Luxembourg": "卢森堡",
                "American Samoa": "美属萨摩亚",
                "Andorra": "安道尔",
                "Ireland": "爱尔兰",
                "Italy": "意大利",
                "Nigeria": "尼日利亚",
                "Turks and Caicos Is.": "特克斯和凯科斯群岛",
                "Ecuador": "厄瓜多尔",
                "U.S. Virgin Is.": "美属维尔京群岛",
                "Brunei": "文莱",
                "Australia": "澳大利亚",
                "Iran": "伊朗",
                "Algeria": "阿尔及利亚",
                "El Salvador": "萨尔瓦多",
                "CÃ´te d'Ivoire": "科特迪瓦",
                "Chile": "智利",
                "Puerto Rico": "波多黎各",
                "Belgium": "比利时",
                "Thailand": "泰国",
                "Haiti": "海地",
                "Iraq": "伊拉克",
                "S?o Tomé and Principe": "圣多美和普林西比",
                "Sierra Leone": "塞拉利昂",
                "Georgia": "格鲁吉亚",
                "Denmark": "丹麦",
                "Philippines": "菲律宾",
                "S. Geo. and S. Sandw. Is.": "南乔治亚岛和南桑威奇群岛",
                "Moldova": "摩尔多瓦",
                "Morocco": "摩洛哥",
                "Namibia": "纳米比亚",
                "Malta": "马耳他",
                "Guinea-Bissau": "几内亚比绍",
                "Kiribati": "基里巴斯",
                "Switzerland": "瑞士",
                "Grenada": "格林纳达",
                "Seychelles": "塞舌尔",
                "Portugal": "葡萄牙",
                "Estonia": "爱沙尼亚",
                "Uruguay": "乌拉圭",
                "Antigua and Barb.": "安提瓜和巴布达",
                "Lebanon": "黎巴嫩",
                "Uzbekistan": "乌兹别克斯坦",
                "Tunisia": "突尼斯",
                "Djibouti": "吉布提",
                "Greenland": "丹麦",
                "Timor-Leste": "东帝汶",
                "Dominica": "多米尼克",
                "Colombia": "哥伦比亚",
                "Burundi": "布隆迪",
                "Bosnia and Herz.": "波斯尼亚和黑塞哥维那",
                "Cyprus": "塞浦路斯",
                "Barbados": "巴巴多斯",
                "Qatar": "卡塔尔",
                "Palau": "帕劳",
                "Bhutan": "不丹",
                "Sudan": "苏丹",
                "Nepal": "尼泊尔",
                "Micronesia": "密克罗尼西亚",
                "Bermuda": "百慕大",
                "Suriname": "苏里南",
                "Venezuela": "委内瑞拉",
                "Israel": "以色列",
                "St. Pierre and Miquelon": "圣皮埃尔和密克隆群岛",
                "Central African Rep.": "中非共和国",
                "Iceland": "冰岛",
                "Zambia": "赞比亚",
                "Senegal": "塞内加尔",
                "Papua New Guinea": "巴布亚新几内亚",
                "Trinidad and Tobago": "特立尼达和多巴哥",
                "Zimbabwe": "津巴布韦",
                "Jordan": "约旦",
                "Gambia": "冈比亚",
                "Kazakhstan": "哈萨克斯坦",
                "Poland": "波兰",
                "Eritrea": "厄立特里亚",
                "Kyrgyzstan": "吉尔吉斯斯坦",
                "Montserrat": "蒙特塞拉特",
                "New Caledonia": "新喀里多尼亚",
                "Macedonia": "马其顿",
                "Paraguay": "巴拉圭",
                "Latvia": "拉脱维亚",
                "Hungary": "匈牙利",
                "Syria": "叙利亚",
                "Honduras": "洪都拉斯",
                "Myanmar": "缅甸",
                "Mexico": "墨西哥",
                "Egypt": "埃及",
                "Nicaragua": "尼加拉瓜",
                "Cuba": "古巴",
                "Serbia": "塞尔维亚",
                "Comoros": "科摩罗",
                "United Kingdom": "英国",
                "Fr. S. Antarctic Lands": "南极洲",
                "Congo": "刚果（布）",
                "Greece": "希腊",
                "Sri Lanka": "斯里兰卡",
                "Croatia": "克罗地亚",
                "Botswana": "博茨瓦纳",
                "Siachen Glacier": "锡亚琴冰川地区"
            };
            var house = [
                // {
                //     houseAdd: "中国",
                //     color: 'red'
                // },
                // {
                //     houseAdd: "Fr. Polynesia",
                //     color: 'blue'
                // }
            ]
            var regions = []; // 定义需要高亮的地区（仓库位置国家）

            dangerCountry({ orgId: this.deptId }).then(res => {
                console.log('风险国家', res.data);
                if (res.data.success && res.data.code == 200) {
                    var color = ''

                    res.data.data.forEach(item => {
                        if (item.dangerRank == 3) {
                            color = '#fd0001'
                        } else if (item.dangerRank == 2) {
                            color = '#f75e02'
                        } else if (item.dangerRank == 1) {
                            color = '#feca02'
                        }
                        house.push({
                            houseAdd: item.country,
                            color: color
                        })
                    })

                    house.forEach((item, i) => {
                        regions.push(
                            {
                                name: item.houseAdd,
                                itemStyle: {
                                    normal: {
                                        areaColor: item.color
                                    },
                                },

                            },
                        );
                    })
                    var option = {
                        geo: {
                            map: 'world',
                            label: {
                                emphasis: {
                                    show: true,
                                    color: '#fff'
                                }
                            },
                            roam: true,
                            itemStyle: {
                                normal: {
                                    areaColor: 'rgba(0, 26, 45, 0)',
                                    borderColor: '#9ac5f0',
                                    borderWidth: 1.5,
                                    shadowColor: '#3697AD',
                                    shadowBlur: 30
                                },
                                emphasis: {
                                    areaColor: 'rgba(0, 26, 45, 0)'
                                }
                            },
                            zoom: 0.8,
                            regions: regions
                        },
                        series: [
                            {
                                type: 'effectScatter',
                                coordinateSystem: 'geo',
                                zlevel: 2,
                                symbolSize: [window.innerWidth / 100 * 0.6, window.innerWidth / 100 * 0.2],
                                rippleEffect: {
                                    brushType: 'stroke',
                                    scale: 5,
                                    period: 8
                                },
                                itemStyle: {
                                    normal: {
                                        color: '#44FCF7'
                                    }
                                },
                                data: that.earthData.map(function (d) {
                                    return {
                                        name: d.name,
                                        value: [d.value[0], d.value[1], 0],
                                        label: {
                                            show: false, // 默认不显示标签
                                            emphasis: { // 高亮状态的样式定义
                                                formatter: function (params) {
                                                    // 根据需要展示的内容定制
                                                    let content = '项目名称\n\n' + params.data.name; // 可以根据data里的属性定制内容
                                                    if (params.data.info) { // 假设数据中还有其他属性info
                                                        content += '\n\n中方人数:    ' + params.data.info.chinesePersonnelNum + '\n\n外籍人数:    ' + params.data.info.foreignNationalsNum;
                                                    }
                                                    return content;
                                                },
                                                position: 'top',
                                                align: 'left',
                                                show: true, // 鼠标悬浮时显示
                                                color: "#fff",
                                                backgroundColor: 'rgba(8, 66, 165, 0.8)',
                                                padding: 10, // 上右下左的内边距分别为 10px
                                                borderWidth: 1, // 边框宽度为 1 像素
                                                borderColor: '#34a0e9', // 红色边框
                                                borderRadius: 5,
                                            }
                                        },
                                        // 假设除了name之外，我们还想显示其他信息，比如info
                                        info: d.info, // 自定义的额外信息
                                    };
                                }),
                            },
                        ]
                    };
                    myChart.resize();
                    window.addEventListener("resize", function () {
                        setTimeout(() => {
                            myChart.resize();
                        }, 0);
                    });
                    myChart.setOption(option)
                    this.loadingState = false
                }
            })
        },
        // 项目整改统计图
        getEchartsLB() {
            echarts.init(document.getElementById('echartsLB')).dispose();
            let that = this;
            var dataX = [];
            var data1 = [];
            var data2 = [];
            var data3 = [];
            var myChart = echarts.init(document.getElementById('echartsLB'));
            let option;
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00147_Y`;
            visualization(params).then((res) => {
                let arr;
                if (res.data.data.ZB00147_Y.length > 0) {
                    res.data.data.ZB00147_Y.forEach((item) => {
                        dataX.push(item.orgName);
                        data3.push(item.value);
                        arr = item.value2.split(",");
                        data1.push(arr[0]);
                        data2.push(arr[1]);
                    });
                    option = {
                        legend: {
                            orient: "horizontal",
                            // icon: 'roundRect',
                            top: "5%",
                            right: "6%",
                            data: ["隐患总数", "已整改", "超期未整改"],
                            itemWidth: 9, // 设置图例图形的宽
                            itemHeight: 9,
                            textStyle: {
                                //图例文字的样式
                                color: "#fff",
                                fontSize: 11,
                            },
                        },
                        grid: {
                            left: "3%",
                            right: "8%",
                            bottom: "3%",
                            containLabel: true,
                        },
                        xAxis: {
                            type: "category",
                            boundaryGap: false,
                            data: [],
                            axisTick: {
                                show: false, //外刻度
                                inside: true, //内刻度
                                length: 5,
                            },
                            axisLabel: {
                                textStyle: {
                                    color: "#FFFFFF", //更改坐标轴文字颜色
                                    fontSize: this.fontSize * 0.729, //更改坐标轴文字大小
                                },
                                formatter: function (value) {
                                    var res = value;
                                    if (res.length > 5) {
                                        res = res.substring(0, 4) + "..";
                                    }
                                    return res;
                                },
                            },
                            axisLine: {
                                show: true,
                                lineStyle: {
                                    color: "#002490", //更改坐标轴颜色
                                },
                            },
                        },

                        yAxis: {
                            show: false,
                            type: "value",
                        },
                        series: [
                            {
                                name: "隐患总数",
                                type: "bar",
                                barWidth: "15",
                                // stack: 'Total',
                                data: [],
                                itemStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0,
                                            0,
                                            0,
                                            1,
                                            [
                                                {
                                                    // 四个数字分别对应 数组中颜色的开始位置，分别为 右，下，左，上。例如（1,0,0,0 ）代表从右边开始渐
                                                    // 变。offset取值为0~1，0代表开始时的颜色，1代表结束时的颜色，柱子表现为这两种颜色的渐变。
                                                    offset: 0,
                                                    color: "#4774db",
                                                },
                                                {
                                                    offset: 1,
                                                    color: "#4774db",
                                                },
                                            ]
                                        ),
                                    },
                                },
                                label: {
                                    normal: {
                                        show: true,
                                        position: "top",
                                        formatter: "{c}",
                                        color: "#fff",
                                        fontSize: 10,
                                    },
                                },
                                // barGap: "-100%"
                            },
                            {
                                name: "已整改",
                                smooth: true,
                                type: "bar",
                                barWidth: "15",
                                // stack: 'Total',
                                data: data2,
                                itemStyle: {
                                    color: "#9fe080",
                                },
                                label: {
                                    normal: {
                                        show: true,
                                        position: "top",
                                        formatter: "{c}",
                                        color: "#fff",
                                        fontSize: 10,
                                    },
                                },
                            },
                            {
                                name: "超期未整改",
                                type: "bar",
                                // stack: 'Total',
                                barWidth: "15",
                                data: [],
                                itemStyle: {
                                    color: "#ec7634",
                                },
                                label: {
                                    normal: {
                                        show: true,
                                        position: "top",
                                        formatter: "{c}",
                                        color: "#fff",
                                        fontSize: 10,
                                    },
                                },
                            },


                        ],
                    };
                    var Interval = null;
                    clearInterval(Interval);
                    InItOpen();
                    Interval = setInterval(InItOpen, 3000);
                    function InItOpen() {
                        if (data1.length > 5) {
                            option.xAxis.data = that.baseData(option, dataX, 5);
                            option.series[0].data = that.baseData(option, data3, 5);
                            option.series[1].data = that.baseData(option, data2, 5);
                            option.series[2].data = that.baseData(option, data1, 5);
                        } else {
                            option.xAxis.data = dataX;
                            option.series[0].data = data3;
                            option.series[1].data = data2;
                            option.series[2].data = data1;
                        }
                        myChart.setOption(option);
                    }
                    option && myChart.setOption(option);
                } else {
                    option = this.echartsNoData();
                    myChart.setOption(option);
                }
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            });
        },
        // 安全培训覆盖率
        getEchartsRT() {
            echarts.init(document.getElementById('echartsRT')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0067_Y&indexCodes=ZB0069_Y&indexCodes=ZB0076_Y`
            visualization(params).then(res => {
                let data = res.data.data
                // console.log('安全培训覆盖率', data);
                this.safetyRate.trainingAmount = data.ZB0067_Y.length == 0 ? 0 : data.ZB0067_Y[0].value // 安全培训次数
                this.safetyRate.trainingPersonnel = data.ZB0069_Y.length == 0 ? 0 : data.ZB0069_Y[0].value // 安全培训人次
                this.safetyRate.rate = data.ZB0076_Y.length == 0 ? 0 : data.ZB0076_Y[0].value * 100
                var myChart = echarts.init(document.getElementById('echartsRT'));
                var option = {
                    title: [
                        {
                            text: this.safetyRate.rate + '%',
                            x: 'center',
                            top: 'center',
                            left: 'center',
                            textStyle: {
                                fontSize: this.fontSize * 1.2,
                                color: '#FFFFFF',
                                fontFamily: 'DINAlternate-Bold, DINAlternate',
                                foontWeight: '600'
                            }
                        }
                    ],
                    polar: {
                        radius: ['65%', '80%'],
                        center: ['50%', '50%']
                    },
                    angleAxis: {
                        max: 100,
                        show: false
                    },
                    radiusAxis: {
                        type: 'category',
                        show: true,
                        axisLabel: {
                            show: false
                        },
                        axisLine: {
                            show: false
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    series: [
                        {
                            name: '',
                            type: 'bar',
                            roundCap: true,
                            barWidth: 90,
                            showBackground: true,
                            backgroundStyle: {
                                color: 'rgba(66, 66, 66, .3)'
                            },
                            data: [this.safetyRate.rate],
                            coordinateSystem: 'polar',
                            itemStyle: {
                                normal: {
                                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                                        {
                                            offset: 0,
                                            color: '#1b9df0'
                                        },
                                        {
                                            offset: 0.3,
                                            color: '#16CEB9'
                                        },
                                        {
                                            offset: 1,
                                            color: 'rgba(0,0,0,0)'
                                        }
                                    ])
                                }
                            }
                        }
                    ]
                };
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        // 培训次数统计
        getEchartsRC() {
            echarts.init(document.getElementById('echartsRC')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB0083_M`
            visualization(params).then(res => {
                // console.log('培训次数统计', res.data.data);

                let data = res.data.data.ZB0083_M
                let monthData = []
                let monthValue = []
                data.forEach(item => {
                    monthData.push(item.month + '月')
                    monthValue.push(item.value)
                })
                var myChart = echarts.init(document.getElementById('echartsRC'));
                var option = null;
                if (data.length > 0) {
                    option = {
                        tooltip: {
                            trigger: 'axis',
                            axisPointer: {
                                type: 'cross',
                                label: {
                                    backgroundColor: '#6a7985'
                                }
                            }
                        },
                        legend: {
                            data: ['实际'],
                            right: '4%',
                            top: '10%',
                            textStyle: {
                                color: '#e3feff',
                                fontSize: this.fontSize * 0.729
                            }
                        },
                        grid: {
                            left: '3%',
                            right: '5%',
                            bottom: '3%',
                            containLabel: true
                        },
                        xAxis: [
                            {
                                type: 'category',
                                boundaryGap: false,
                                data: monthData,
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.729
                                    }
                                },
                            }
                        ],
                        yAxis: [
                            {
                                type: 'value',
                                name: '单位：次数',
                                splitLine: {
                                    lineStyle: {
                                        type: "dashed",
                                        color: "#396389"
                                    }
                                },
                                nameTextStyle: {
                                    color: '#e3feff',
                                    fontSize: this.fontSize * 0.729
                                },
                                axisLabel: {
                                    show: true,
                                    textStyle: {
                                        color: '#e3feff',
                                        fontSize: this.fontSize * 0.729
                                    }
                                },
                            }
                        ],
                        series: [
                            {
                                name: '实际',
                                type: 'line',
                                stack: 'Total',
                                smooth: true,
                                areaStyle: {
                                    normal: {
                                        color: new echarts.graphic.LinearGradient(
                                            0,
                                            0,
                                            0,
                                            1,
                                            [
                                                {
                                                    offset: 0,
                                                    color: 'rgba(27, 154, 237,0.5)'
                                                },
                                                {
                                                    offset: 1,
                                                    color: 'rgba(27, 154, 237,0.1)'
                                                }
                                            ],
                                            false
                                        ),
                                        shadowBlur: 10
                                    }
                                },
                                emphasis: {
                                    focus: 'series'
                                },
                                data: monthValue
                            }
                        ]
                    };
                } else {
                    option = this.echartsNoData()

                }
                myChart.setOption(option)
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
            })
        },
        // 年度安全检查统计
        getEchartsRB() {
            echarts.init(document.getElementById('echartsRB')).dispose();
            let params = `_year=${this.nowYear}&_month&companyId=${this.deptId}&limit&indexCodes=ZB00108_Y&indexCodes=ZB0042_Y&indexCodes=ZB0041_Y`
            visualization(params).then(res => {
                // let data = res.data.data
                // console.log('年度安全检查统计', res.data.data);

                let data = res.data.data
                let allAmount = data.ZB00108_Y.length == 0 ? 0 : data.ZB00108_Y[0].value
                let pieSource = {
                    专项检测: data.ZB0042_Y.length == 0 ? 0 : data.ZB0042_Y[0].value,
                    项目自查次数: 0,
                    政府检查次数: data.ZB0041_Y.length == 0 ? 0 : data.ZB0041_Y[0].value,
                    领导带班检查次数: 0
                }

                var legendColor = ['#5EC4FF', '#ECE427', '#12E7A3', '#00d1ff']
                var richStyle = {};
                var source = this.initData(pieSource, legendColor, richStyle);
                var myChart = echarts.init(document.getElementById('echartsRB'));
                var option = {
                    title: {
                        text: '总数量',
                        subtext: allAmount + '',
                        textStyle: {
                            fontSize: this.fontSize * 0.729,
                            color: '#9dc0d9',
                            fontWeight: 200
                        },
                        subtextStyle: {
                            fontSize: this.fontSize * 1.563,
                            color: '#9fe5ff',
                            fontWeight: 800
                        },
                        textAlign: 'center',
                        left: '19%',
                        top: '35%'
                    },
                    tooltip: {
                        trigger: 'item',
                        // textStyle: {
                        //     color: '#003eff'
                        //     // fontSize:28
                        // },
                        formatter: '{a} <br/>{b} : {c} 次'
                    },
                    color: legendColor,
                    legend: [
                        {
                            orient: 'vertical',
                            left: '40%',
                            y: 'center',
                            icon: 'circle',
                            textStyle: {
                                color: '#bfe4fe',
                                fontSize: this.fontSize * 0.729,
                                rich: richStyle,
                                width: this.fontSize * 10.417,
                            },
                            // padding: [this.fontSize * 2.7778, 0, this.fontSize * 2.7778, 0],
                            itemWidth: 10,
                            itemHeight: 8,
                            itemGap: 20,
                            data: source.legendData.slice(0, 4),
                            formatter: function (name) {
                                let oa = option.series[0].data;
                                let total = 0;
                                oa.forEach((item, index) => {
                                    total += item.value;
                                });
                                for (let i = 0; i < oa.length; i++) {
                                    if (name == oa[i].name) {
                                        // return name + '  ' + '{style'+i+'|' + (oa[i].value / total * 100).toFixed(2) + '%}';
                                        return (
                                            name + '{style' + i + '|' + oa[i].value + '次}'
                                        );
                                    }
                                }
                            }
                        }
                    ],
                    series: [
                        {
                            name: '年度安全检查统计',
                            type: 'pie',
                            radius: ['40%', '50%'],
                            center: ['20%', '50%'],
                            data: source.source,
                            itemStyle: {
                                normal: {
                                    label: {
                                        show: false
                                    },
                                    shadowColor: '#438bff',
                                    // shadowBlur: 10,
                                    borderWidth: 3,
                                    borderColor: 'rgba(0,0,0,0.3)'
                                }
                            }
                        }
                    ]
                };
                window.addEventListener("resize", function () {
                    myChart.resize();
                });
                myChart.setOption(option)
            })
        },
        initData(source, legendColor, richStyle) {
            legendColor.map((item, index) => {
                richStyle['style' + index] = {};
                richStyle['style' + index]['color'] = item;
                richStyle['style' + index]['align'] = 'right';
            });
            if (Object.prototype.toString.call(source) === '[object Object]') {
                const arr = [];
                const legendData = [];
                Object.entries(source).forEach((value) => {
                    legendData.push(value[0]);
                    let obj = {};
                    obj.name = value[0];
                    obj.value = value[1];
                    arr.push(obj);
                });
                source = arr;
                return {
                    source,
                    legendData
                };
            } else if (
                source &&
                Object.prototype.toString.call(source) === '[object Array]'
            ) {
                let legendData = [];
                source.forEach((item, index) => {
                    legendData.push(item.name);
                });
                return {
                    source,
                    legendData
                };
            }
        },
        // 班前会及交底
        bqhFunction() {
            let that = this;
            let data = {
                current: 1,
                size: 999,
                companyId: this.companyId,
                createDept: this.deptId,
                draft: 0,
            };
            dbtbeforeclass(data).then((res) => {
                res.data.data.records.forEach((item) => {
                    let urs = JSON.parse(item.filesUser);
                    // item.filesUser = urs[0].link;
                    item.imgSrc = urs[0]?.link;
                    item.meetingTime = item.meetingTime.split(" ")[0];
                });
                that.BQJDHList = that.transformData(res.data.data.records);
            });
        },
        // 班前会数据格式处理
        transformData(originalData) {
            let transformedData = [];
            let currentPage = { data: [] };
            for (let i = 0; i < originalData.length; i++) {
                if (i % 2 === 0) {
                    if (currentPage.data.length > 0) {
                        transformedData.push(currentPage);
                        currentPage = { data: [] };
                    }
                    currentPage.data.push(originalData[i]);
                } else {
                    currentPage.data.push(originalData[i]);
                }
            }
            if (currentPage.data.length > 0) {
                transformedData.push(currentPage);
            }
            return transformedData;
        },
        // 无数据处理
        echartsNoData() {
            return {
                title: {
                    text: "{B|}",
                    left: 'center',
                    top: 'center',
                    textStyle: {
                        rich: {
                            B: {
                                backgroundColor: {
                                    image: require('../../../assets/visualization/noData.png'),
                                },
                                height: this.fontSize * 6,
                            },
                        }
                    },
                }
            }
        },
        // 动态数据
        baseData(option, name, size) {
            var NameData = [];
            var Data_Name = name;
            Data_Name.push(Data_Name[0])
            Data_Name.splice(0, 1);
            for (var i = 0; i < Data_Name.length; i++) {
                NameData.push(Data_Name[i]);
                if ((i + 1) == size) {
                    break;
                }
            }
            return NameData;
        },
        // 背景雨
        rain() {
            var c = document.querySelector(".rain");
            var ctx = c.getContext("2d");//获取canvas上下文
            var w = c.width = document.querySelector('.rainBox').clientWidth;
            var h = c.height = document.querySelector('.rainBox').clientHeight;
            //设置canvas宽、高

            function random(min, max) {
                return Math.random() * (max - min) + min;
            }

            function RainDrop() { }
            //雨滴对象 这是绘制雨滴动画的关键
            RainDrop.prototype = {
                init: function () {
                    this.x = random(0, w);//雨滴的位置x
                    this.y = h;//雨滴的位置y
                    // this.color = 'hsl(180, 100%, 50%)';//雨滴颜色 长方形的填充色
                    this.color = 'hsl(180, 100%, 50%)';//雨滴颜色 长方形的填充色
                    this.vy = random(5, 0.2);//雨滴下落速度
                    this.hit = 0;//下落的最大值
                    this.size = 2;//长方形宽度
                },
                draw: function () {
                    if (this.y > this.hit) {
                        var linearGradient = ctx.createLinearGradient(this.x, this.y, this.x, this.y + this.size * 30)
                        // 设置起始颜色
                        linearGradient.addColorStop(0, '#34d4f1')
                        // 设置终止颜色
                        linearGradient.addColorStop(1, '#19498a')
                        // 设置填充样式
                        ctx.fillStyle = linearGradient
                        ctx.fillRect(this.x, this.y, this.size, this.size * 2);//绘制长方形，通过多次叠加长方形，形成雨滴下落效果
                    }
                    this.update();//更新位置
                },
                update: function () {
                    if (this.y > this.hit) {
                        this.y -= this.vy;//未达到底部，增加雨滴y坐标
                    } else {
                        this.init();
                    }
                }
            };

            function resize() {
                w = c.width = document.querySelector('.rainBox').clientWidth;
                h = c.height = document.querySelector('.rainBox').clientHeight;
            }

            //初始化一个雨滴

            var rs = []
            for (var i = 0; i < 10; i++) {
                setTimeout(function () {
                    var r = new RainDrop();
                    r.init();
                    rs.push(r);
                }, i * 300)
            }

            function anim() {
                ctx.clearRect(0, 0, w, h);//填充背景色，注意不要用clearRect，否则会清空前面的雨滴，导致不能产生叠加的效果
                for (var i = 0; i < rs.length; i++) {
                    rs[i].draw();//绘制雨滴
                }
                requestAnimationFrame(anim);//控制动画帧
            }

            window.addEventListener("resize", resize);
            //启动动画
            anim()
        },
        rain2() {
            var c = document.querySelector(".rain2");
            var ctx = c.getContext("2d");//获取canvas上下文
            var w = c.width = document.querySelector('.rainBox').clientWidth;
            var h = c.height = document.querySelector('.rainBox').clientHeight;
            //设置canvas宽、高

            function random(min, max) {
                return Math.random() * (max - min) + min;
            }

            function RainDrop() { }
            //雨滴对象 这是绘制雨滴动画的关键
            RainDrop.prototype = {
                init: function () {
                    this.x = random(0, w);//雨滴的位置x
                    this.y = h;//雨滴的位置y
                    // this.color = 'hsl(180, 100%, 50%)';//雨滴颜色 长方形的填充色
                    this.color = 'hsl(180, 100%, 50%)';//雨滴颜色 长方形的填充色
                    this.vy = random(5, 0.2);//雨滴下落速度
                    this.hit = 0;//下落的最大值
                    this.size = 2;//长方形宽度
                },
                draw: function () {
                    if (this.y > this.hit) {
                        var linearGradient = ctx.createLinearGradient(this.x, this.y, this.x, this.y + this.size * 30)
                        // 设置起始颜色
                        linearGradient.addColorStop(0, '#34d4f1')
                        // 设置终止颜色
                        linearGradient.addColorStop(1, '#19498a')
                        // 设置填充样式
                        ctx.fillStyle = linearGradient
                        ctx.fillRect(this.x, this.y, this.size, this.size * 2);//绘制长方形，通过多次叠加长方形，形成雨滴下落效果
                    }
                    this.update();//更新位置
                },
                update: function () {
                    if (this.y > this.hit) {
                        this.y -= this.vy;//未达到底部，增加雨滴y坐标
                    } else {
                        this.init();
                    }
                }
            };

            function resize() {
                w = c.width = document.querySelector('.rainBox').clientWidth;
                h = c.height = document.querySelector('.rainBox').clientHeight;
            }

            //初始化一个雨滴

            var rs = []
            for (var i = 0; i < 10; i++) {
                setTimeout(function () {
                    var r = new RainDrop();
                    r.init();
                    rs.push(r);
                }, i * 300)
            }

            function anim() {
                ctx.clearRect(0, 0, w, h);//填充背景色，注意不要用clearRect，否则会清空前面的雨滴，导致不能产生叠加的效果
                for (var i = 0; i < rs.length; i++) {
                    rs[i].draw();//绘制雨滴
                }
                requestAnimationFrame(anim);//控制动画帧
            }

            window.addEventListener("resize", resize);
            //启动动画
            anim()
        },
        rain3() {
            var c = document.querySelector(".rain3");
            var ctx = c.getContext("2d");//获取canvas上下文
            var w = c.width = document.querySelector('.rainBox').clientWidth;
            var h = c.height = document.querySelector('.rainBox').clientHeight;
            //设置canvas宽、高

            function random(min, max) {
                return Math.random() * (max - min) + min;
            }

            function RainDrop() { }
            //雨滴对象 这是绘制雨滴动画的关键
            RainDrop.prototype = {
                init: function () {
                    this.x = random(0, w);//雨滴的位置x
                    this.y = h;//雨滴的位置y
                    // this.color = 'hsl(180, 100%, 50%)';//雨滴颜色 长方形的填充色
                    this.color = 'hsl(180, 100%, 50%)';//雨滴颜色 长方形的填充色
                    this.vy = random(5, 0.2);//雨滴下落速度
                    this.hit = 0;//下落的最大值
                    this.size = 2;//长方形宽度
                },
                draw: function () {
                    if (this.y > this.hit) {
                        var linearGradient = ctx.createLinearGradient(this.x, this.y, this.x, this.y + this.size * 30)
                        // 设置起始颜色
                        linearGradient.addColorStop(0, '#34d4f1')
                        // 设置终止颜色
                        linearGradient.addColorStop(1, '#19498a')
                        // 设置填充样式
                        ctx.fillStyle = linearGradient
                        ctx.fillRect(this.x, this.y, this.size, this.size * 2);//绘制长方形，通过多次叠加长方形，形成雨滴下落效果
                    }
                    this.update();//更新位置
                },
                update: function () {
                    if (this.y > this.hit) {
                        this.y -= this.vy;//未达到底部，增加雨滴y坐标
                    } else {
                        this.init();
                    }
                }
            };

            function resize() {
                w = c.width = document.querySelector('.rainBox').clientWidth;
                h = c.height = document.querySelector('.rainBox').clientHeight;
            }

            //初始化一个雨滴

            var rs = []
            for (var i = 0; i < 10; i++) {
                setTimeout(function () {
                    var r = new RainDrop();
                    r.init();
                    rs.push(r);
                }, i * 300)
            }

            function anim() {
                ctx.clearRect(0, 0, w, h);//填充背景色，注意不要用clearRect，否则会清空前面的雨滴，导致不能产生叠加的效果
                for (var i = 0; i < rs.length; i++) {
                    rs[i].draw();//绘制雨滴
                }
                requestAnimationFrame(anim);//控制动画帧
            }

            window.addEventListener("resize", resize);
            //启动动画
            anim()
        },
    }
};
</script>
<style lang="scss" scoped>
@import url('./public.css');
@import url('./xg.css');

::v-deep .el-input__inner {
    background-color: transparent !important;
    border: 0;
    font-family: 'PingFangSt';
    color: #fff;
    font-size: 1vw;
    font-style: italic;
    text-shadow: 0 0 12px #006affcc;
    padding: 5px;
    font-weight: bold;
    padding-right: 35px;
    white-space: nowrap;
    /* 禁止换行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 显示省略号 */
}

::v-deep .el-select__caret {
    color: #fff !important;
    font-size: 1.563vw !important;
}

::v-deep .el-input {
    display: flex;
    align-items: center;
}

#app {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;

    .main {
        width: 100vw;
        // height: 56.25vw;
        height: 100vh;
        background: url('../../../assets/visualization/IntelligentSecurity/backgroundOld.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        padding-top: 5.208vw;

        .fullScreen {
            position: fixed;
            top: 1.563vw;
            right: 2.344vw;
            font-size: 18px;
            color: #E3F3FF;
        }

        >div {
            position: relative;
            z-index: 2;
        }

        .header {
            display: flex;
            color: #CDD6e3;
            justify-content: center;
            height: 3.125vw;
            z-index: 999999;

            .dateTime {
                display: flex;
                flex-direction: column;
                align-items: flex-end;

                span:first-child {
                    font-size: 0.938vw;
                }

                span:last-child {
                    font-size: 0.625vw;
                }
            }

            .statistics {
                width: 10.677vw;
                height: 3.125vw;
                margin-left: 3.125vw;
                display: flex;

                >div:first-child {
                    width: 3.125vw;
                    height: 3.125vw;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                >div:last-child {
                    width: calc(10.677vw - 3.125vw);
                    height: 3.125vw;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;

                    span:first-child {
                        font-size: 0.729vw;
                        color: #E3F3FF;
                    }

                    span:last-child {
                        font-size: 1.458vw;

                        span {
                            font-size: 0.573vw;
                            color: #E3F3FF;
                        }
                    }
                }
            }

            .switchProject {
                width: 13.021vw;
                height: 2.083vw;
                display: flex;
                align-items: center;
                margin-left: 3.125vw;
            }
        }

        .body {
            height: calc(100% - 3.125vw);
            // padding: 1.042vw 4.167vw 3.125vw;
            padding: 1.042vw;
            display: flex;
            position: relative;
            z-index: 9;


            >div:first-child {
                margin-right: 1.3889vh;
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 9999;

                >div {
                    flex: 1;
                    // margin-bottom: 1.3889vh;
                }

                >div:last-child {
                    margin-bottom: 0;
                }
            }

            >div:nth-child(2) {
                width: 50%;
                margin-right: 1.3889vh;

                .earthMap {
                    position: relative;
                    z-index: 888;
                    // background: url('../../../assets/visualization/IntelligentSecurity/lightRingNew.png');
                    // background-repeat: no-repeat;
                    // background-size: auto 85%;
                    background-position: center;
                    width: 100%;
                    height: 100%;
                    margin-bottom: 1.3889vh;

                    #earthMap,
                    #wordMap {
                        position: fixed !important;
                        top: 4.208vw;
                        left: 0;
                        z-index: 8 !important;
                        height: calc(100% - 4.208vw) !important;
                        padding-bottom: 5vh;
                    }

                    >div {
                        width: 100%;
                        height: 100%;
                    }
                }

                // .center-bottom {
                //     width: 100%;
                //     height: calc(33.7% - 1.3889vh);
                //     display: flex;
                //     margin-top: 1.3889vh;

                //     >div:first-child {
                //         margin-right: 1.3889vh;
                //     }
                // }
            }

            >div:last-child {
                flex: 1;
                display: flex;
                flex-direction: column;
                position: relative;
                z-index: 9999;

                >div {
                    flex: 1;
                    margin-bottom: 1.3889vh;
                }

                >div:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .rainBox {
        position: absolute;
        width: 80% !important;
        height: 70% !important;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%);
        z-index: 1;

        canvas {
            position: absolute;
            bottom: 0;
        }
    }

}

// 重载样式
.echartsModelS>.echartsModelS-chart {
    width: 100%;
    height: calc(100% - 4.1667vh);
    background-image: none;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

@keyframes shan {
    0% {
        opacity: 1;
    }

    50% {
        opacity: 0.5;
    }

    100% {
        opacity: 1;
    }
}

.elItem {
    width: 100%;
    display: flex;
    height: 47%;
    justify-content: space-between;
}

.elItemImageBox {
    width: 40%;
}

.elItemImageBox img {
    width: 100%;
    height: 100%;
}

.LBtxt {
    width: 55%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pojList {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 1vh;
    z-index: 9;
    // box-sizing: border-box;
}

.hideBox {
    height: 91%;
    width: 17.1979vw;
    background-image: url(../../../assets/visualization/securityManagement/pojListBG.png);
    background-size: 100% 100%;
    float: right;
    margin-top: 9vh;
    padding: 1vh;
}

.hideBox>div:last-child {
    height: 95%;
    overflow: scroll;
    overflow-x: hidden;
}

.hideBox>div:last-child::-webkit-scrollbar {
    width: 4px;
}

.scroll {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.scroll>span {
    width: 16vw;
    padding: 1vh;
    display: flex;
    align-items: center;
    font-size: 1.5vh;
    color: white !important;
    font-weight: 400 !important;
}

.scroll>span img {
    margin-right: 1vh;
}

.scroll>span:hover {
    background-image: url(../../../assets/visualization/securityManagement/chkBG.png);
    background-size: 100% 100%;
    color: #1ed2ff !important;
}

.scroll>span:hover>.oneChk {
    display: none;
}

.scroll>span:hover>.twoChk {
    display: block !important;
}

::v-deep .el-input--small {
    margin: 0.5vh 0;
}

::v-deep .el-carousel__item {
    font-size: 0.729vw;
    padding: 1.5vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

::v-deep .el-input__inner {
    // background-color: rgba(0, 0, 0, 0);
    // border: 1px solid rgba(0, 0, 0, 0);
    // background-image: url(../../../assets/visualization/securityManagement/pojIntBg.png);
    // background-size: 100% 100%;
    color: #f1f1f1;
}

.loadshow {
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: end !important;
}

.hertaFont {
    background-image: url(../../../assets/visualization/securityManagement/BTNIMG.png);
    background-size: 100% 100%;
    padding: 6px 18px;
}

.herdStyle {
    color: #5ad8eb;
    font-size: 2vh;
    // flex: 1;
}

.pojImg {
    width: 20px;
}

.avueinputTree {
    width: 50% !important;
}

.Nodataavailableatthemoment {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}

.Nodataavailableatthemoment img {
    height: 100px;
}

.dian2 {
    display: inline-block;
    width: 100%;
    // white-space: nowrap; /* 禁止换行 */
    // overflow: hidden; /* 超出部分隐藏 */
    // text-overflow: ellipsis; /* 显示省略号 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    /* 限制在两行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 显示省略号 */
}

.dian1 {
    display: inline-block;
    width: 100%;
    // white-space: nowrap; /* 禁止换行 */
    // overflow: hidden; /* 超出部分隐藏 */
    // text-overflow: ellipsis; /* 显示省略号 */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    /* 限制在两行 */
    overflow: hidden;
    /* 超出部分隐藏 */
    text-overflow: ellipsis;
    /* 显示省略号 */
}

.echartsModelS {
    width: 100%;
    height: 33.3333%;
    margin-bottom: 0 !important;
}

.el-carousel {
    height: 100%;
}

// 在建项目，运营机构概况
.listBoxNew {
    display: flex;
    font-size: 0.726vw;
    color: #CDD6e3;
    padding: 10px;

    >div {
        width: 33.3333%;
        height: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .listBox-title {
            height: 20%;
            text-align: center;
            padding-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .itemS,
        .itemL {
            height: 40%;
            background-image: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: 0;
            padding: 0;

            img {
                height: 55%;
            }

            span {
                width: 50%;
                height: 50%;
                font-size: 0.625vw;
                display: flex;
                align-items: center;
            }
        }
    }

}
</style>
