<template>
  <div>
    <div style="position: relative;" class="browseHead">
      <head-layout :head-title="headTitle" :head-btn-options="headBtnOptions">
      </head-layout>
      <div class="btn-container" v-if="detailRow.status === 3" style="margin-right: 100px">
        <el-button size="medium" :loading="collectLoading" :icon="isCollect ? 'el-icon-star-on' : 'el-icon-star-off'"
          @click="clickCollect">收藏
        </el-button>
        <el-button size="medium" icon="el-icon-download" @click="handleFileClick">下载
        </el-button>
        <el-button size="medium" icon="el-icon-help" @click="clickShare">分享</el-button>
        <el-button size="medium" icon="el-icon-s-comment" @click="assessment">评价</el-button>
      </div>
      <div class="btn-container">
        <el-button size="medium" icon="el-icon-close" @click="occlude">关闭</el-button>
      </div>
    </div>
    <el-container>
      <el-main v-loading="iframeLoading">
        <iframe ref="fileIframe" id="iframe" :src="iframeUrl" frameborder="0"
          style="width: 100%;height: calc(100vh - 46px)"></iframe>
      </el-main>
      <div class="right-container" :style="{ paddingLeft: rightShow ? '' : '16px' }" v-if="this.detailRow.status === 3">
        <!-- <el-button type="primary" :icon="rightShow ? 'el-icon-arrow-right' : 'el-icon-arrow-left'"
            class="rightBtn" circle @click="rightShow = !rightShow"></el-button> -->
        <!--        <img class="rightBtn" @click="rightShow = !rightShow" :src="rightShow ? rightPng : leftPng" alt="">-->
        <el-button class="rightBtn" v-if="rightShow"
          style="margin-right: 5px;clip-path:polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);background:#d2d3d6" circle
          type="primary" icon="el-icon-arrow-right" @click="rightShow = !rightShow"></el-button>
        <el-button class="rightBtn" circle v-else type="primary" icon="el-icon-arrow-left"
          style="margin-right: 5px;clip-path:polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);background:#d2d3d6"
          @click="rightShow = !rightShow"></el-button>
        <transition name="el-fade-in-linear">
          <el-tabs v-model="activeName" @tab-click="handleClick" v-show="rightShow">
            <el-tab-pane label="文档组合" name="word">
              <CommonTree style="height: 100%;" :treeData="treeFileData" :defaultProps="defaultFileProps"
                :searchTitle="searchFileTitle" :isShowdig="false" :showCheckbox="false" :titleShow="false"
                treeTitle="文档组合" @getNodeClick="treeFileNodeClick" />
            </el-tab-pane>
            <el-tab-pane label="知识详情" name="konwledge">
              <div class="content-knowledge">
                <div style="line-height: 46px;">文件动态</div>
                <div class="knowledge-item">
                  <div>
                    <span><i class="el-icon-view"></i></span>
                    <span>预览</span>
                  </div>
                  <span>{{ tableKnowledgeInfo.browseCount }}</span>
                </div>
                <div class="knowledge-item">
                  <div>
                    <span><i class="el-icon-star-on"></i></span>
                    <span>收藏</span>
                  </div>
                  <span>{{ tableKnowledgeInfo.collectCount }}</span>
                </div>
                <div class="knowledge-item">
                  <div>
                    <span><i class="el-icon-download"></i></span>
                    <span>下载</span>
                  </div>
                  <span>{{ tableKnowledgeInfo.downloadCount }}</span>
                </div>
                <div class="knowledge-item">
                  <div>
                    <span><i class="el-icon-help"></i></span>
                    <span>分享</span>
                  </div>
                  <span>{{ tableKnowledgeInfo.shareCount }}</span>
                </div>
                <el-divider></el-divider>
                <div>文件属性</div>
                <div class="knowledge-text">上传时间</div>
                <div class="knowledge-text">{{ tableKnowledgeInfo.updateTime }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>

        </transition>
      </div>
    </el-container>

    <common-dialog
      v-if="showDialog"
      width="60%"
      :dialogTitle="detailRow.fileName + '评价'"
      @cancel="closeDialog"
      @confirm="handleNodeSave">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="评价维度1">
          <el-rate v-model="form.attr1"></el-rate>
        </el-form-item>
        <el-form-item label="评价维度2">
          <el-rate v-model="form.attr2"></el-rate>
        </el-form-item>
        <el-form-item label="评价维度3">
          <el-rate v-model="form.attr3"></el-rate>
        </el-form-item>
        <el-form-item label="评价说明">
          <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.remark" maxlength="200" show-word-limit>
          </el-input>
        </el-form-item>
      </el-form>
      <el-main>
        <head-layout head-title="知识评价"></head-layout>
        <div style="max-height: 300px; overflow-y: auto; padding: 0 12px">
          <div v-for="item in assessmentList" :key="item.id" class="assessment-content">
            <div class="assessment-avatar">
              <el-avatar :size="50" :src="item.assessmentUserAvatar"></el-avatar>
              <span>{{ item.assessmentUserName }}</span>
            </div>
            <div class="assessment-item">
              <div class="assessment-rate">
                <el-rate v-model="item.attr1" disabled show-score text-color="#ff9900" score-template="{value}">
                </el-rate>
                <el-rate v-model="item.attr2" disabled show-score text-color="#ff9900" score-template="{value}">
                </el-rate>
                <el-rate v-model="item.attr3" disabled show-score text-color="#ff9900" score-template="{value}">
                </el-rate>
              </div>
              <p>{{ item.remark }}</p>
            </div>
          </div>
        </div>
      </el-main>
    </common-dialog>

    <common-dialog
      v-if="showUserDialog"
      width="65%"
      class="user-dialog"
      dialogTitle="分享"
      :show-copy-btn="true"
      @copy="copyLink"
      @cancel="showUserDialog = false"
      @confirm="handleSaveShare">
      <el-container>
        <el-main>
          <form-layout ref="formLayout" :column="formColumn" :dataForm.sync="dataForm"></form-layout>
        </el-main>
      </el-container>
    </common-dialog>

  </div>
</template>

<script>
// import color from "@/mixins/color";
import {
  collect,
  saveAssessment,
  assessmentDetail,
  browseCount,
  getDetail,
  saveShareCount,
  submitRecording,
  infoTree,
  assessmentList,
  selectByInfoId,
  removeCollect,
  listAll, saveDownloadCount
} from "@/api/knowledgeClassifcation/knowledge";
import { getDeptTree } from "@/api/system/dept";
import { getList } from "@/api/system/user";
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree";
import { mapGetters } from "vuex";
import { downloadFileBlob } from "@/util/util";
import HeadLayout from "@/views/components/layout/head-layout";
import website from "@/config/website";
import CommonDialog from "@/components/CommonDialog";

const Base64 = require("js-base64").Base64;
export default {
  // mixins: [color()],
  data() {
    return {
      detailRow: {},
      dataForm: {},
      showDialog: false,
      rightShow: true,
      iframeLoading: false,
      form: {
        attr1: null,
        attr2: null,
        attr3: null,
        remark: "",
      },
      isCollect: true,
      collect: [],
      query: {},
      collectLoading: true,
      //   用户
      showUserDialog: false,
      treeData: [],
      searchTitle: "title",
      defaultProps: {
        label: "title",
        value: "key",
      },
      treeDeptId: "",
      gridRowBtn: [],
      tableData: [],
      tableLoading: false,
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      tableOptions: {
        menu: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.account"),
            prop: "account",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.tenantName"),
            prop: "tenantName",
            cell: true,
            hide: "",
          },
          {
            label: this.$t("cip.plat.sys.user.field.realName"),
            prop: "realName",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.roleName"),
            prop: "roleName",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.deptName"),
            prop: "deptName",
            cell: true,
          },
          {
            label: this.$t("cip.plat.sys.user.field.isEnabled"),
            prop: "isEnabled",
            cell: true,
            dataType: "number",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=ISENBLE",
          },
          {
            label: this.$t("cip.plat.sys.user.field.userType"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            align: "center",
            dataType: "number",
            prop: "userType",
            hide: true,
          },
        ],
      },
      selectionList: [],
      iframeUrl: "",
      fileInfo: "",
      showFileDialog: false,
      fullscreen: true,
      treeFileData: [],
      treeFileLoading: false,
      searchFileTitle: "knowTypeName",
      defaultFileProps: {
        label: "knowTypeName",
        value: "id",
        key: "id",
      },
      assessmentList: [],
      headTitle: '',
      activeName: 'word',
      tableKnowledgeInfo: {},
      pageKnowledge: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      knowledgeId: '',
      rightPng: require('@/assets/images/right.png'),
      leftPng: require('@/assets/images/left.png'),
    };
  },

  components: {
    GridLayout,
    CommonTree,
    HeadLayout,
    CommonDialog,
  },

  computed: {
    ...mapGetters(["userInfo", "themeColor"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    formColumn() {
      return [
        {
          label: "分享链接",
          prop: "shareLink",
          readonly: true,
          span: 18,
          type: 'input',
          placeholder: "分享链接",
          rules: [{
            required: false,
            message: "请输入分享链接",
            trigger: "blur"
          }]
        },
      ]
    },
    searchColumns() {
      return [
        {
          label: "",
          prop: "account",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.account"),
        },
        {
          label: "",
          prop: "realName",
          span: 4,
          placeholder:
            this.$t("cip.cmn.rule.inputWarning") +
            this.$t("cip.plat.sys.user.field.realName"),
        },
        {
          prop: "roleId",
          span: 4,
          multiple: true,
          type: "tree",
          dicUrl: "api/sinoma-system/role/treeByUserType?tenantId=000000",
          props: {
            label: "title",
          },
          checkStrictly: true,
          slot: true,
          placeholder:
            this.$t("cip.cmn.rule.selectWarning") +
            this.$t("cip.plat.sys.user.field.roleName"),
        },
        {
          label: "",
          prop: "postId",
          span: 4,
          type: "tree",
          multiple: true,
          dicUrl: "/api/sinoma-system/post/select?tenantId=000000",
          props: {
            label: "postName",
            value: "id",
          },
          placeholder:
            this.$t("cip.cmn.rule.selectWarning") +
            this.$t("cip.plat.sys.user.field.postId"),
        },
      ];
    },
    headBtnOptions() {
      let buttonBtn = [];
      return buttonBtn;
    },
  },
  created() {
    let url = window.location.href;
    let p = url.split("?")[1];
    let id = "";
    if (p) {
      let query = new URLSearchParams(p);
      id = query.get("id");
      this.knowledgeId = id;
      this.headTitle = query.get('fileName');
      this.openDialog(id);
    }
  },
  mounted() {
  },

  methods: {
    headCancel() {
      window.close();
    },
    handleClick(e) {
      if (e.name == 'konwledge') {
        this.onLoadKnowledge(this.pageKnowledge)
      }
    },
    onLoadKnowledge(page) {
      this.pageKnowledge = page;
      listAll(page.currentPage, page.pageSize, '', '', this.knowledgeId)
        .then((res) => {
          const data = res.data.data;
          if(data.records.length > 0){
            this.tableKnowledgeInfo = data.records[0];
          }
        })
    },
    initTree(id) {
      this.treeFileLoading = true;
      infoTree(id).then((result) => {
        this.treeFileData = result.data.data;
        this.treeFileLoading = false;
      });
    },
    treeFileNodeClick(node) {
      if (node.isDeleted == -1) {
        // this.detail(node.id);
        this.getIframeUrl(node.id);
      }
    },
    closeFileDialog() {
      this.showFileDialog = false;
      this.fullscreen = false;
    },
    handleClose() {
      this.$parent.onLoad({ pageSize: 20, currentPage: 1 });
      this.showFileDialog = false;
    },
    fullClick() {
      this.fullscreen = !this.fullscreen;
    },
    openDialog(id) {
      // this.detail(id);
      this.getIframeUrl(id);
      this.selectCollect(id);
      this.showFileDialog = true;
    },
    getIframeUrl(id) {
      getDetail(id).then((res) => {
        if (res.data.code == 200) {
          this.detailRow = res.data.data;
          this.tableKnowledgeInfo = res.data.data
          this.fileInfo = res.data.data.attachList[0];
          this.iframeLoading = true;
          this.iframeUrl =
            process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
            encodeURIComponent(Base64.encode(this.fileInfo.link));

            this.initTree(id);

            this.$nextTick(() =>{
              let that = this;
              let iframe = document.getElementById("iframe");
              if (iframe.attachEvent) {
                iframe.attachEvent("onload", function() {
                  that.iframeLoading = false;
                });
              } else {
                iframe.onload = function() {
                  that.iframeLoading = false;
                };
              }
            })

          //exls表格预览隐藏打印按钮
          if (process.env.NODE_ENV === "production") {
            const iframe = this.$refs.fileIframe;
            const iframeDocument =
              iframe.contentDocument || iframe.contentWindow.document;

            // 在 iframe 内部隐藏元素
            if (iframeDocument) {
              const elementToHide =
                iframeDocument.getElementById("button-area");
              if (elementToHide) {
                elementToHide.style.display = "none";
              }
            }
          }
          if (this.detailRow.status === 3) {
            this.addBrowse();
          }
        }
      });
    },
    handleFileClick() {
      downloadFileBlob(this.fileInfo.link, this.fileInfo.originalName);
      let params = {
        knowInfoId: this.detailRow.id,
        knowInfoCode: this.detailRow.fileCode,
      };
      saveDownloadCount(params).then((res) => {
        if (res.data.code == 200) {
          this.$nextTick(() => {
            this.$message({
              message: "操作成功",
              type: 'success'
            });
          })
          this.onLoadKnowledge(this.pageKnowledge)
        }
      });
    },
    addBrowse() {
      let params = {
        knowInfoId: this.detailRow.id,
        knowInfoCode: this.detailRow.fileCode,
      };
      browseCount(params);
    },
    selectCollect(id) {
      let params = {
        knowInfoId: this.detailRow.id,
        knowInfoCode: this.detailRow.fileCode,
      };
      selectByInfoId(id).then((res) => {
        if (res.data.data.length > 0) {
          this.collect = res.data.data
          this.isCollect = true;
        } else {
          this.isCollect = false;
        }
        this.collectLoading = false;
      });
    },
    detail(id) {
      assessmentDetail(id).then((res) => {
        if (res.data.code == 200 && res.data.data) {
          this.form = res.data.data;
        } else {
          this.form = {};
        }
      });
    },
    clickCollect() {
      if (this.isCollect) {
        this.collectLoading = true;
        removeCollect(this.collect[0].id).then((res) => {
          if (res.data.code === 200) {
            this.isCollect = false;
            this.collectLoading = false;
            this.$message({
              type: "success",
              message: "取消收藏成功",
            });
          }
        });
      } else {
        this.collectLoading = true;
        let params = {
          knowInfoId: this.detailRow.id,
          knowInfoCode: this.detailRow.fileCode,
        };
        collect(params).then((res) => {
          if (res.data.code === 200) {
            this.selectCollect(this.detailRow.id);
            this.$message({
              message: "收藏成功",
              type: "success",
            });
          }
        });
      }
    },
    occlude() {
      window.close();
    },
    assessment() {
      this.showDialog = true;
      this.form = {};
      this.getAssessmentList();
    },
    getAssessmentList() {
      assessmentList(this.detailRow.id).then((res) => {
        if (res.data.code == 200) {
          this.assessmentList = res.data.data;
        }
      });
    },
    closeDialog() {
      this.showDialog = false;
      this.$refs.form.resetField();
    },
    handleNodeSave() {
      let params = {
        knowInfoId: this.detailRow.id,
        ...this.form,
      };
      saveAssessment(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "评价成功",
            type: "success",
          });
          this.showDialog = false;
        }
      });
    },
    getNodeClick(data) {
      this.treeDeptId = data.id;
      this.page.currentPage = 1;
      this.onLoad(this.page);
    },
    includeDown(e) {
      this.page.currentPage = 1;
      this.query.isInclude = e;
      this.onLoad(this.page);
    },
    getTreeData() {
      getDeptTree().then((res) => {
        this.treeData = res.data.data;
      });
    },
    searchChange(params) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params)
    },
    searchReset() {
      this.treeDeptId = "";
      this.page.currentPage = 1;
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.tableLoading = true;
      getList({
        ...params,...this.query,
        deptId:this.treeDeptId,
        current:this.currentPage,
        size:this.pageSize,
      }
      ).then((res) => {
        const data = res.data.data;
        // this.$refs.gridLayout.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
      });
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    clickShare() {

      this.dataForm.shareLink = process.env.VUE_APP_BASE_KKFILEURL + "?url=" +
        encodeURIComponent(Base64.encode(this.fileInfo.link));
      this.showUserDialog = true;
      this.$nextTick(() => {
        this.getTreeData();
        // this.onLoad(this.page);
        let shareLink = window.location.href;
        let params = {
          knowInfoId: this.detailRow.id,
          knowInfoCode: this.detailRow.fileCode,
          shareUserIds: this.ids,
          shareLink: shareLink,
        };
        submitRecording(params).then((res) => {
          // if (res.data.code == 200) {
          //   this.$message({
          //     message: "分享成功",
          //     type: "success",
          //   });
          //   this.showUserDialog = false;
          // }
        });
      });
    },
    copyLink() {
      //创建input标签
      var input = document.createElement('input')
      //将input的值设置为需要复制的内容
      input.value = this.dataForm.shareLink;
      //添加input标签
      document.body.appendChild(input)
      //选中input标签
      input.select()
      //执行复制
      document.execCommand('copy')
      //成功提示信息
      this.$message.success('复制成功!')
      //移除input标签
      document.body.removeChild(input)
    },
    handleSaveShare() {
      this.showUserDialog = false;

      // if (this.selectionList.length == 0) {
      //   this.$message({
      //     type: "warning",
      //     message: "请选择人员",
      //   });
      // } else {
      //   let shareLink = window.location.href;
      //   let params = {
      //     knowInfoId: this.detailRow.id,
      //     knowInfoCode: this.detailRow.fileCode,
      //     shareUserIds: this.ids,
      //     shareLink: shareLink,
      //   };
      //   saveShareCount(params).then((res) => {
      //     if (res.data.code == 200) {
      //       this.$message({
      //         message: "分享成功",
      //         type: "success",
      //       });
      //       this.showUserDialog = false;
      //     }
      //   });
      // }
    },
  },
};
</script>
<style>
.file-dialog .el-dialog__body {
  padding: 0 10px 10px 10px !important;
}
</style>
<style scoped lang='scss'>
.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 5px;
  right: 12px;

  ::v-deep .el-button:focus,
  ::v-deep .el-button:hover {
    color: #303133;
    border-color: #f6f7fa;
    background-color: #f6f7fa;
  }
}

.user-dialog {
  ::v-deep .el-dialog__body {
    padding: 0 20px !important;
  }
}

.common_tree_handle {
  border-right: none !important;
  border-left: 1px solid #cccccc;
}

.assessment-content {
  padding: 10px 0;
  display: flex;
  border-bottom: 1px solid #cccccc;

  .assessment-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 30px;
    width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex-shrink: 0;

    span {
      padding-top: 10px;
      text-align: center;
    }
  }

  .assessment-item {
    .assessment-rate {
      .el-rate {
        padding-right: 30px;
      }
    }
  }
}

.right-container {
  padding-left: 10px;
  position: relative;
}

.rightBtn {
  width: 48px;
  height: 96px;
  position: absolute;
  left: -20px;
  top: calc(50% - 48px);
  z-index: 99;
}

.smallContainer {
  height: 85vh;
  overflow: auto;
}

::v-deep .el-tabs__header {
  margin: 0;
}

::v-deep .el-tabs__item {
  color: #d2d3d6;
}

::v-deep .el-tabs__item.is-active {
  color: #303133;
}

::v-deep .el-tabs__active-bar {
  background-color: #303133;
}

::v-deep .el-tabs__item:hover {
  color: #303133;
}

.content-knowledge {
  width: 256px;
  height: calc(100vh - 86px);
  padding: 12px;
  background: #ffffff;

  .knowledge-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #f6f7fa;
    padding: 8px 10px;
    border-radius: 2px;
    margin-bottom: 6px;

    span {
      color: #606266;
      font-size: 14px;
      font-weight: 400;

      i {
        font-size: 16px;
        margin-right: 10px;
      }
    }
  }

  .knowledge-text {
    color: #606266;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
  }
}
.browseHead ::v-deep .head-layout{
  padding: 15px 0 12px 12px;
}
</style>
