var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "browseHead", staticStyle: { position: "relative" } },
        [
          _c("head-layout", {
            attrs: {
              "head-title": _vm.headTitle,
              "head-btn-options": _vm.headBtnOptions,
            },
          }),
          _vm.detailRow.status === 3
            ? _c(
                "div",
                {
                  staticClass: "btn-container",
                  staticStyle: { "margin-right": "100px" },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        size: "medium",
                        loading: _vm.collectLoading,
                        icon: _vm.isCollect
                          ? "el-icon-star-on"
                          : "el-icon-star-off",
                      },
                      on: { click: _vm.clickCollect },
                    },
                    [_vm._v("收藏\n      ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", icon: "el-icon-download" },
                      on: { click: _vm.handleFileClick },
                    },
                    [_vm._v("下载\n      ")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", icon: "el-icon-help" },
                      on: { click: _vm.clickShare },
                    },
                    [_vm._v("分享")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "medium", icon: "el-icon-s-comment" },
                      on: { click: _vm.assessment },
                    },
                    [_vm._v("评价")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", icon: "el-icon-close" },
                  on: { click: _vm.occlude },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.iframeLoading,
                  expression: "iframeLoading",
                },
              ],
            },
            [
              _c("iframe", {
                ref: "fileIframe",
                staticStyle: { width: "100%", height: "calc(100vh - 46px)" },
                attrs: { id: "iframe", src: _vm.iframeUrl, frameborder: "0" },
              }),
            ]
          ),
          this.detailRow.status === 3
            ? _c(
                "div",
                {
                  staticClass: "right-container",
                  style: { paddingLeft: _vm.rightShow ? "" : "16px" },
                },
                [
                  _vm.rightShow
                    ? _c("el-button", {
                        staticClass: "rightBtn",
                        staticStyle: {
                          "margin-right": "5px",
                          "clip-path":
                            "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                          background: "#d2d3d6",
                        },
                        attrs: {
                          circle: "",
                          type: "primary",
                          icon: "el-icon-arrow-right",
                        },
                        on: {
                          click: function ($event) {
                            _vm.rightShow = !_vm.rightShow
                          },
                        },
                      })
                    : _c("el-button", {
                        staticClass: "rightBtn",
                        staticStyle: {
                          "margin-right": "5px",
                          "clip-path":
                            "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                          background: "#d2d3d6",
                        },
                        attrs: {
                          circle: "",
                          type: "primary",
                          icon: "el-icon-arrow-left",
                        },
                        on: {
                          click: function ($event) {
                            _vm.rightShow = !_vm.rightShow
                          },
                        },
                      }),
                  _c(
                    "transition",
                    { attrs: { name: "el-fade-in-linear" } },
                    [
                      _c(
                        "el-tabs",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.rightShow,
                              expression: "rightShow",
                            },
                          ],
                          on: { "tab-click": _vm.handleClick },
                          model: {
                            value: _vm.activeName,
                            callback: function ($$v) {
                              _vm.activeName = $$v
                            },
                            expression: "activeName",
                          },
                        },
                        [
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "文档组合", name: "word" } },
                            [
                              _c("CommonTree", {
                                staticStyle: { height: "100%" },
                                attrs: {
                                  treeData: _vm.treeFileData,
                                  defaultProps: _vm.defaultFileProps,
                                  searchTitle: _vm.searchFileTitle,
                                  isShowdig: false,
                                  showCheckbox: false,
                                  titleShow: false,
                                  treeTitle: "文档组合",
                                },
                                on: { getNodeClick: _vm.treeFileNodeClick },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "el-tab-pane",
                            { attrs: { label: "知识详情", name: "konwledge" } },
                            [
                              _c(
                                "div",
                                { staticClass: "content-knowledge" },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "line-height": "46px" } },
                                    [_vm._v("文件动态")]
                                  ),
                                  _c("div", { staticClass: "knowledge-item" }, [
                                    _c("div", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-view",
                                        }),
                                      ]),
                                      _c("span", [_vm._v("预览")]),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tableKnowledgeInfo.browseCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "knowledge-item" }, [
                                    _c("div", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-star-on",
                                        }),
                                      ]),
                                      _c("span", [_vm._v("收藏")]),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tableKnowledgeInfo.collectCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "knowledge-item" }, [
                                    _c("div", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-download",
                                        }),
                                      ]),
                                      _c("span", [_vm._v("下载")]),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tableKnowledgeInfo.downloadCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("div", { staticClass: "knowledge-item" }, [
                                    _c("div", [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "el-icon-help",
                                        }),
                                      ]),
                                      _c("span", [_vm._v("分享")]),
                                    ]),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.tableKnowledgeInfo.shareCount
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _c("el-divider"),
                                  _c("div", [_vm._v("文件属性")]),
                                  _c("div", { staticClass: "knowledge-text" }, [
                                    _vm._v("上传时间"),
                                  ]),
                                  _c("div", { staticClass: "knowledge-text" }, [
                                    _vm._v(
                                      _vm._s(_vm.tableKnowledgeInfo.updateTime)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.showDialog
        ? _c(
            "common-dialog",
            {
              attrs: {
                width: "60%",
                dialogTitle: _vm.detailRow.fileName + "评价",
              },
              on: { cancel: _vm.closeDialog, confirm: _vm.handleNodeSave },
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: { model: _vm.form, "label-width": "80px" },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "评价维度1" } },
                    [
                      _c("el-rate", {
                        model: {
                          value: _vm.form.attr1,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "attr1", $$v)
                          },
                          expression: "form.attr1",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评价维度2" } },
                    [
                      _c("el-rate", {
                        model: {
                          value: _vm.form.attr2,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "attr2", $$v)
                          },
                          expression: "form.attr2",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评价维度3" } },
                    [
                      _c("el-rate", {
                        model: {
                          value: _vm.form.attr3,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "attr3", $$v)
                          },
                          expression: "form.attr3",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "评价说明" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入内容",
                          maxlength: "200",
                          "show-word-limit": "",
                        },
                        model: {
                          value: _vm.form.remark,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remark", $$v)
                          },
                          expression: "form.remark",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-main",
                [
                  _c("head-layout", { attrs: { "head-title": "知识评价" } }),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        "max-height": "300px",
                        "overflow-y": "auto",
                        padding: "0 12px",
                      },
                    },
                    _vm._l(_vm.assessmentList, function (item) {
                      return _c(
                        "div",
                        { key: item.id, staticClass: "assessment-content" },
                        [
                          _c(
                            "div",
                            { staticClass: "assessment-avatar" },
                            [
                              _c("el-avatar", {
                                attrs: {
                                  size: 50,
                                  src: item.assessmentUserAvatar,
                                },
                              }),
                              _c("span", [
                                _vm._v(_vm._s(item.assessmentUserName)),
                              ]),
                            ],
                            1
                          ),
                          _c("div", { staticClass: "assessment-item" }, [
                            _c(
                              "div",
                              { staticClass: "assessment-rate" },
                              [
                                _c("el-rate", {
                                  attrs: {
                                    disabled: "",
                                    "show-score": "",
                                    "text-color": "#ff9900",
                                    "score-template": "{value}",
                                  },
                                  model: {
                                    value: item.attr1,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attr1", $$v)
                                    },
                                    expression: "item.attr1",
                                  },
                                }),
                                _c("el-rate", {
                                  attrs: {
                                    disabled: "",
                                    "show-score": "",
                                    "text-color": "#ff9900",
                                    "score-template": "{value}",
                                  },
                                  model: {
                                    value: item.attr2,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attr2", $$v)
                                    },
                                    expression: "item.attr2",
                                  },
                                }),
                                _c("el-rate", {
                                  attrs: {
                                    disabled: "",
                                    "show-score": "",
                                    "text-color": "#ff9900",
                                    "score-template": "{value}",
                                  },
                                  model: {
                                    value: item.attr3,
                                    callback: function ($$v) {
                                      _vm.$set(item, "attr3", $$v)
                                    },
                                    expression: "item.attr3",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c("p", [_vm._v(_vm._s(item.remark))]),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.showUserDialog
        ? _c(
            "common-dialog",
            {
              staticClass: "user-dialog",
              attrs: {
                width: "65%",
                dialogTitle: "分享",
                "show-copy-btn": true,
              },
              on: {
                copy: _vm.copyLink,
                cancel: function ($event) {
                  _vm.showUserDialog = false
                },
                confirm: _vm.handleSaveShare,
              },
            },
            [
              _c(
                "el-container",
                [
                  _c(
                    "el-main",
                    [
                      _c("form-layout", {
                        ref: "formLayout",
                        attrs: {
                          column: _vm.formColumn,
                          dataForm: _vm.dataForm,
                        },
                        on: {
                          "update:dataForm": function ($event) {
                            _vm.dataForm = $event
                          },
                          "update:data-form": function ($event) {
                            _vm.dataForm = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }