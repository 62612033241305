import { render, staticRenderFns } from "./browse.vue?vue&type=template&id=26577b20&scoped=true"
import script from "./browse.vue?vue&type=script&lang=js"
export * from "./browse.vue?vue&type=script&lang=js"
import style0 from "./browse.vue?vue&type=style&index=0&id=26577b20&lang=css"
import style1 from "./browse.vue?vue&type=style&index=1&id=26577b20&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "26577b20",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/hse-web-227/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('26577b20')) {
      api.createRecord('26577b20', component.options)
    } else {
      api.reload('26577b20', component.options)
    }
    module.hot.accept("./browse.vue?vue&type=template&id=26577b20&scoped=true", function () {
      api.rerender('26577b20', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/business/knowledge/knowledgeSearch/browse/browse.vue"
export default component.exports